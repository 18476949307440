import styles from './MorePopup.module.scss';
import { FC, useRef } from 'react';
import { openDialog, dialogData } from '#store/openDialog';

interface Props {
  slide: {
    content: string;
    rating: string;
    author: string;
  };
  moreButton: string;
}

const MoreButton: FC<Props> = ({ slide, moreButton }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const description = contentRef?.current;
  const status = description && description.scrollHeight > description.clientHeight;
  const markIfLongText = (className: string) => {
    return `${className} ${status ? styles.long : ''}`;
  };

  const handeMoreBtnClick = () => {
    openDialog.set(true);
    dialogData.set(slide);
  };

  return (
    <>
      <div className={markIfLongText(styles.content)} ref={contentRef}>
        {slide.content}
      </div>
      <button className={markIfLongText(styles.moreButton)} onClick={() => handeMoreBtnClick()}>
        {moreButton}
      </button>
    </>
  );
};
export default MoreButton;
