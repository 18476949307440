import styles from './Reviews.module.scss';

import Slider from '#components/ui/Slider';
import QuotesIcon from '#components/ui/Icons/Quotes';
import Rating from '#components/ui/Rating';
import MoreButton from '#components/ui/MoreButton';

type Props = {
  slides: [
    {
      author: string;
      rating: string;
      content: string;
    },
  ];
  moreButton: string;
};

const ReviewsSlides = ({ slides, moreButton }: Props) => {
  const renderSlide = (slide: { author: string; rating: string; content: string }) => (
    <div className={styles.card}>
      <div className={styles.titleWrapper}>
        <QuotesIcon />
        <h3 className={styles.authorName}>{slide.author}</h3>
      </div>
      <div>
        <Rating rating={slide.rating} />
      </div>
      <MoreButton slide={slide} moreButton={moreButton} />
    </div>
  );

  return (
    <Slider
      className={styles.reviewsSlider}
      slides={slides}
      renderSlide={renderSlide}
      slidesPerViewDesktop={3}
      slidesPerViewMobile="auto"
      sliderType="reviews"
      scrollbar={true}
    />
  );
};

export default ReviewsSlides;
